//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class NotaEntradaServices {
    Consultar(
        datos
    ) {
        return http.get("notaEntrada/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    ConsultarDetalles(
        datos
    ) {
        return http.get("notaEntrada/consultar/detalle", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    Actualizar(
        IdUsuario,
        Datos
    ) {
        const datos = {
            IdUsuario: IdUsuario,
            Data: Datos
        }
        return http.post("notaEntrada/actualizar", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    

  Anular(
    IdUsuario,
    Data
  ) {
    const datos = {
      IdUsuario,
      Data
    }
    console.log(datos)
    return http.post("/notaEntrada/anular", datos)
      .catch((error) => {
        if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }
      });
  }

}

export default new NotaEntradaServices();