<template>
  <v-card>
    <v-card-subtitle>Nota de entrada</v-card-subtitle>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col sm="6" cols="12">
            <v-row>
              <v-col :cols="notaEntrada.idArea && notaEntrada.notaEntradaDetalle.length > 0 ? 10 : 12">
                <AreaSelect
                  :rules="reglas.requerido"
                  :disabled="notaEntrada.idArea && notaEntrada.notaEntradaDetalle.length > 0"
                  v-model="notaEntrada.idArea"
                  :dense="true"
                  :outlined="true"
                >
                </AreaSelect>
              </v-col>
              <v-col cols="2" v-if="notaEntrada.idArea && notaEntrada.notaEntradaDetalle.length > 0">
                <v-btn class="mt-1" icon color="primary" small @click="cambiarArea()">
                  <v-icon>{{ icons.mdiRefresh }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="6" cols="12">
            <ProveedorSelect
              :rules="reglas.requerido"
              :clearable="true"
              v-model="notaEntrada.idProveedor"
              :dense="true"
              :outlined="true"
            >
            </ProveedorSelect>
          </v-col>
          <v-col sm="6" cols="12">
            <DatePicker
              v-model="notaEntrada.fecha"
              :dense="true"
              :outlined="true"
              :label="`Fecha Nota Entrada`"
              :rules="reglas.requerido"
            ></DatePicker>
          </v-col>
          <v-col cols="12">
            <v-textarea label="Observacion" v-model="notaEntrada.observacion" outlined dense rows="3"> </v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-row class="mb-1">
      <v-col>
        <v-card-subtitle>Productos</v-card-subtitle>
      </v-col>

      <v-col class="text-right">
        <v-btn class="ml-2" @click="abrirModalBusquedaProductoA()" small color="primary">Busqueda avanzada</v-btn>
      </v-col>
      <v-col>
        <BuscardorProductoBarra v-model="producto" ref="BuscardorProductoBarraRef"></BuscardorProductoBarra>
      </v-col>
    </v-row>

    <v-card-text>
      <template>
        <v-simple-table fixed-header height="300px" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Producto</th>
                <th class="text-right">Existencia Actual</th>
                <th class="text-right">Cantidad Entrada</th>
                <th class="text-right">Existencia Final</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in notaEntrada.notaEntradaDetalle" :key="i">
                <td @click="editar(item)">
                  <small> {{ item.producto.producto.barra }} </small>
                  <br />
                  {{ item.producto.producto.nombre }}
                  <br />
                  <small v-if="item.producto.lote">
                    Lote: {{ FuncionesGenerales.camelCase(item.producto.lote.numeroLote) }}
                  </small>
                </td>

                <td class="text-right" @click="editar(item)">
                  <span :class="item.existenciaActual >= 0 ? 'success--text' : 'error--text'">
                    {{ item.existenciaActual }}
                  </span>
                </td>
                <td class="text-right" @click="editar(item)">
                  <span :class="item.totalUnidades >= 0 ? 'success--text' : 'error--text'">
                    <span v-if="item.totalUnidades > 0">+</span>
                    {{ item.totalUnidades }}
                  </span>
                </td>
                <td class="text-right" @click="editar(item)">
                  <span :class="item.existenciaFinal >= 0 ? 'success--text' : 'error--text'">
                    {{ item.existenciaFinal }}
                  </span>
                </td>
                <td class="text-center">
                  <v-btn @click="eliminarItem(item)" color="error" icon x-small>
                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-card-text>

    <v-card-text class="text-right">
      <v-btn small color="secondary mr-2" @click="limpiar()" :disabled="guardando"> Limpiar </v-btn>
      <v-btn small color="primary mr-2" @click="guardar()" :loading="guardando"> Guardar </v-btn>
    </v-card-text>
    <ModalBusquedaProductoAvanzado
      @GetProductos="GetProductos"
      :columnas="['ID', 'NOMBRE', 'CODIGO', 'EXISTENCIA']"
      :fullscreen="true"
      :soloExistencia="false"
      :IndExistencia="false"
      ref="ModalBusquedaProductoAvanzadoRef"
      :idArea="notaEntrada.idArea"
    >
    </ModalBusquedaProductoAvanzado>
    <ModalEditarProducto ref="ModalEditarProductoRef"></ModalEditarProducto>
    <ModalFinalizar :estatus="estatus" @aceptar="guardarDB" ref="ModalFinalizarRef"></ModalFinalizar>
  </v-card>
</template>

<script>
import AreaSelect from '../../archivo/area/componentes/AreaSelect.vue'
import BuscardorProductoBarra from '../../../producto/catalogo/producto/componentes/BuscardorProductoBarra.vue'
import ModalBusquedaProductoAvanzado from '../../../producto/catalogo/producto/componentes/ModalBusquedaProductoAvanzado.vue'
import ProveedorSelect from '@/views/sistema/producto/catalogo/proveedor/componentes/Select.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { mdiDelete, mdiAlert, mdiRefresh } from '@mdi/js'
import store from '@/store'
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker.vue'
import NotaEntradaServices from '@/api/servicios/NotaEntradaServices'
import ModalEditarProducto from './ModalEditarProducto.vue'
import ModalFinalizar from './ModalFinalizar.vue'

export default {
  components: {
    AreaSelect,
    BuscardorProductoBarra,
    ModalBusquedaProductoAvanzado,
    DatePicker,
    ProveedorSelect,
    ModalEditarProducto,
    ModalFinalizar,
  },
  setup(props, context) {
    const BuscardorProductoBarraRef = ref(null)
    const ModalBusquedaProductoAvanzadoRef = ref(null)
    const ModalEditarProductoRef = ref(null)
    const ModalFinalizarRef = ref(null)
    const form = ref()
    const estatus = [
      {
        id: 31,
        descripcion: 'Borrador',
      },
      {
        id: 32,
        descripcion: 'Finalizado',
      },
    ]
    let identity = -10000
    const identityGlobal = () => {
      identity = identity + 1
      return identity
    }
    const notaEntradaInit = {
      id: -1 /*int*/,
      idArea: null /*int*/,
      idEstatus: 12 /*int*/,
      idProveedor: null,
      idUsuarioResponsable: null /*int*/,
      numeroDocumento: '' /*string*/,
      observacion: '' /*string*/,
      cantidadItems: 0 /*int*/,
      fecha: null /*DateTime*/,
      notaEntradaDetalle: [],
    }

    const detalleInit = {
      id: 0 /*int*/,
      idProductoSucursal: 0 /*int*/,
      idLote: 0 /*int*/,
      idMotivoNotaEntrada: 0 /*int*/,
      observacion: '' /*string*/,
      entradas: 0 /*decimal*/,
      salidas: 0 /*decimal*/,
      existenciaActual: 0 /*decimal*/,
      totalUnidades: 0 /*decimal*/,
      existenciaFinal: 0 /*decimal*/,
    }
    const notaEntrada = ref({ ...notaEntradaInit })
    const editar = item => {
      ModalEditarProductoRef.value.abrir(item)
    }

    const GetProductos = datosIn => {
      console.log(datosIn)

      datosIn.forEach(element => {
        let existeProducto = false
        if (
          notaEntrada.value.notaEntradaDetalle.find(
            it => it.idProductoSucursal == element.id && (element.lote == null || it.idLote == element.lote.id),
          )
        ) {
          existeProducto = true
        } else {
          existeProducto = false
        }

        if (!existeProducto) {
          notaEntrada.value.notaEntradaDetalle.push({
            id: identityGlobal(),
            idProductoSucursal: element.id,
            idLote: element.lote ? element.lote.id : null,
            observacion: '',
            entradas: 0,
            salidas: 0,
            existenciaActual: element.lote ? element.lote.existenciaLote.inventario : element.existencia.inventario,
            totalUnidades: 0,
            existenciaFinal: element.lote ? element.lote.existenciaLote.inventario : element.existencia.inventario,
            producto: element,
          })
        }
      })
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }
    const eliminarItem = datoIn => {
      notaEntrada.value.notaEntradaDetalle = notaEntrada.value.notaEntradaDetalle.filter(i => i.id !== datoIn.id)
    }
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }
    const cambiarArea = () => {
      Vue.swal({
        icon: 'info',
        html:
          '<h3>¿Seguro que quiere cambiar de area? </h3>' +
          `<small>Se eliminaran todos los notaEntradaDetalle.</small> `,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          notaEntrada.value.idArea = null
          notaEntrada.value.notaEntradaDetalle = []
        },
        allowOutsideClick: () => !Vue.swal.isLoading(),
      })
    }
    const guardando = ref(false)
    const guardarDB = estatus => {
      console.log(estatus)
      if (estatus.id != undefined) {
        notaEntrada.value.idEstatus = estatus.id
        guardando.value = true
        NotaEntradaServices.Actualizar(store.state.user.idUsuario, notaEntrada.value)
          .then(response => {
            if (response.data.estatus == true) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              GetListar(response.data.datos.datos)
              limpiar()
            } else {
              store.commit('setAlert', {
                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                type: 'warning',
              })
            }
          })
          .catch(err => {
            store.commit('setAlert', {
              message: err,
              type: 'error',
            })
          })
          .finally(() => {
            guardando.value = false
          })
      } else {
        store.commit('setAlert', {
          message: `error al seleccionar el estatus`,
          type: 'warning',
        })
      }
    }
    const guardar = () => {
      if (validarForm()) {
        if (notaEntrada.value.notaEntradaDetalle.length > 0) {
          ModalFinalizarRef.value.abrir()
        } else {
          store.commit('setAlert', {
            message: 'Debe agregar por lo menos un producto',
            type: 'info',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'verifique todos los los datos',
          type: 'info',
        })
      }
    }
    const limpiar = () => {
      notaEntrada.value = { ...notaEntradaInit }
      notaEntrada.value.notaEntradaDetalle = []
    }

    const abrirModalBusquedaProductoA = () => {
      ModalBusquedaProductoAvanzadoRef.value.abrirModal(true)
    }
    const cargandoDatos = ref(false)
    const GetEditar = item => {
      console.log('GetEditar')
      console.log(item)
      NotaEntradaServices.ConsultarDetalles({ id: item.id })
        .then(response => {
          console.log(response)
          if (response.data.estatus == true) {
            notaEntrada.value = {
              ...response.data.datos[0],
              notaEntradaDetalle: [],
              fecha: FuncionesGenerales.formatoFecha(response.data.datos[0].fecha, 4),
            }
            response.data.datos[0].notaEntradaDetalle.forEach(it => {
              notaEntrada.value.notaEntradaDetalle.push({
                ...it,
                producto: { ...it.productoSucursal, lote: it.lote },
              })
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    const GetListar = item => {
      context.emit('GetListar', item)
    }
    return {
      notaEntrada,
      BuscardorProductoBarraRef,
      GetProductos,
      ModalBusquedaProductoAvanzadoRef,
      abrirModalBusquedaProductoA,
      ModalEditarProductoRef,
      editar,
      FuncionesGenerales,
      icons: {
        mdiDelete,
        mdiAlert,
        mdiRefresh,
      },
      cambiarArea,
      eliminarItem,
      limpiar,
      form,
      reglas,
      guardar,
      guardando,
      ModalFinalizarRef,
      estatus,
      guardarDB,
      GetEditar,
      GetListar,
    }
  },
}
</script>
