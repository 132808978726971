<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="900px">
            <ViewNotaEntrada :VerButton="VerButton" @GetCerrarModal="GetCerrarModal" @GetAnular="GetAnular" @GetEditar="GetEditar" @GetPdf="GetPdf"
                @GetFinalizar="GetFinalizar" :idNotaEntrada="idNotaEntrada" :modal="true"></ViewNotaEntrada>
        </v-dialog>
    </v-row>
</template>
<script>
import { ref } from '@vue/composition-api';
import ViewNotaEntrada from './ViewNotaEntrada.vue';
export default {
    components: {
        ViewNotaEntrada
    },
    props:{
        VerButton:{
            type:Boolean,
            default: true
        }
    },
    setup(props, context) {
        const dialog = ref(false)
        const ViewNotaEntradaRef = ref(null)
        const idNotaEntrada = ref(null)
        const abrir = (idNotaEntradain) => {
            idNotaEntrada.value = idNotaEntradain
            dialog.value = true
        }
        const GetCerrarModal = () => {
            dialog.value = false
        }
        const GetAnular = (item) => {
            context.emit('GetAnular', item)
            dialog.value = false
        }
        const GetEditar = (item) => {

            context.emit('GetEditar', item)
            dialog.value = false
        }
        const GetPdf = (item) => {
            context.emit('GetPdf', item)
        }
        const GetFinalizar = (item) => {
            context.emit('GetFinalizar', item)
            dialog.value = false
        }

        return {
            dialog,
            abrir,
            idNotaEntrada,
            GetCerrarModal,
            GetAnular,
            GetEditar,
            GetPdf,
            GetFinalizar
        }
    },
}
</script>
