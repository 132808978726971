
<template>
  <div>
    <v-card>
      <v-card-subtitle
        >Lista

        <v-btn icon @click="cargar()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" offset-md="8" md="4">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Buscar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="Lista"
        :search="search"
        :items-per-page="5"
        :loading="cargandoDatos"
        class="table-kitchen-sink"
      >
        <template #[`item.fechaNotaEntrada`]="{ item }">
          {{ FuncionesGenerales.formatoFecha(item.fechaNotaEntrada, 1) }}
        </template>
        <template #[`item.fechaCreacion`]="{ item }">
          {{ FuncionesGenerales.formatoFecha(item.fechaCreacion, 3) }}
        </template>

        <template #[`item.accion`]="{ item }">
          <BtnPdfNotaEntrada :id="item.id" :tipoBtn="2"></BtnPdfNotaEntrada>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-1" v-bind="attrs" v-on="on" @click="GetVer(item)">
                <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
              </a>
            </template>
            <span>Ver</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-1" v-bind="attrs" v-on="on" @click="GetFinalizar(item)">
                <v-icon :color="item.idEstatus == 10 ? 'success' : 'grey3'">{{ icons.mdiCheck }}</v-icon>
              </a>
            </template>
            <span>Finalizar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-1" v-bind="attrs" v-on="on" @click="GetEditar(item)">
                <v-icon :color="item.idEstatus == 10 ? 'info' : 'grey3'">{{ icons.mdiPencil }}</v-icon>
              </a>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-1" v-bind="attrs" v-on="on" @click="GetAnular(item)">
                <v-icon :color="item.idEstatus != 33 ? 'error' : 'grey3'">{{ icons.mdiCancel }}</v-icon>
              </a>
            </template>
            <span>Anular</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <ModalViewNotaEntrada
      @GetAnular="GetAnular"
      @GetEditar="GetEditar"
      @GetFinalizar="GetFinalizar"
      ref="ModalViewNotaEntradaRef"
    ></ModalViewNotaEntrada>
  </div>
</template>
   
  <script>
import { onBeforeMount, ref, context, watch } from '@vue/composition-api'
import ModalViewNotaEntrada from './ModalViewNotaEntrada.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiPhoneOutline,
  mdiEmail,
  mdiWeb,
  mdiEye,
  mdiFilePdfBox,
} from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import NotaEntradaServices from '@/api/servicios/NotaEntradaServices'
import Pdf from '@/components/Pdf.vue'
import FuncionesGenerales from '@/funciones/funciones'
import Vue from 'vue'
import { acciones } from '@/modulos'
export default {
  watch: {
    '$store.state.sucursalSelect.id': function () {
      this.cargar()
    },
  },
  components: {
    Pdf,
    ModalViewNotaEntrada,
  },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const ModalViewNotaEntradaRef = ref(null)
    const pdfRef = ref(null)

    const GetEditar = item => {
      if (item.idEstatus == 10) {
        console.log(item)
        context.emit('GetEditar', item)
      }
    }
    const anulando = ref(false)
    const GetAnular = async item => {
   
      let Hash = 0
      if (!FuncionesGenerales.validarAcciones(acciones.NOTA_DE_ENTRADA_ANULAR, store.state.sucursalSelect.id)) {
        Hash = await FuncionesGenerales.HashsConsultar()
      } else {
        Hash = 3
      }

      if (Hash == 3) {
        if (item.idEstatus != 33) {
          anulando.value = true
          Vue.swal({
            icon: 'warning',
            html: '<h3>¿Seguro que quiere anular el notaEntrada? </h3>' + `<p>${item.numeroDocumento}</p>`,
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro',
            showLoaderOnConfirm: true,
            cancelButtonText: 'No',
            preConfirm: () => {
              return NotaEntradaServices.Anular(store.state.user.idUsuario, { id: item.id })
                .then(response => {
                  if (response.data.estatus == true) {
                    Vue.swal({
                      icon: 'success',
                      title: `Exito`,
                      text: response.data.mensaje,
                    })
                    cargar()
                  } else {
                    Vue.swal({
                      icon: 'error',
                      title: `Oops...`,
                      text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                    })
                  }
                  return true
                })
                .catch(error => {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `hubo un error: ${error}`,
                  })
                  return false
                })
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(result => {
            elimiandoPago.value = false
          })
        }
      } else {
        Vue.swal({
          icon: 'error',
          text: `No tienes permiso para realizar esta accion`,
        })
      }
    }
    onBeforeMount(() => {
      cargar()
    })
    const GetVer = item => {
      ModalViewNotaEntradaRef.value.abrir(item.id)
    }

    const cargar = () => {
      try {
        NotaEntradaServices.Consultar({ idSucursal: store.state.sucursalSelect.id })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              Lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        cargandoDatos.value = false
      }
    }
    const CambiarIndActivo = item => {
      console.log('aqui')
    }

    const GetFinalizar = item => {
      if (item.idEstatus == 10) {
        Vue.swal({
          icon: 'warning',
          html: '<h3>¿Seguro que quiere finalizar el notaEntrada? </h3>' + `<p>${item.numeroDocumento}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Si, Seguro',
          showLoaderOnConfirm: true,
          cancelButtonText: 'No',
          preConfirm: () => {
            return NotaEntradaServices.Finalizar(store.state.user.idUsuario, { id: item.id })
              .then(response => {
                if (response.data.estatus == true) {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  cargar()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `${response.data.mensaje}: ${response.data.datos.mensajeError}`,
                  })
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                return false
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          elimiandoPago.value = false
        })
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiPhoneOutline,
        mdiEmail,
        mdiWeb,
        mdiEye,
        mdiFilePdfBox,
      },
      search,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Area', value: 'area.nombre' },
        { text: 'Estatus', value: 'estatus.descripcion' },
        { text: 'Fecha Nota Entrada', value: 'fecha' },
        { text: 'Fecha Creacion', value: 'fechaCreacion' },
        { text: 'Numero Documento', value: 'numeroDocumento' },
        { text: 'Accion', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetEditar,
      cargar,
      config,
      cargandoDatos,
      CambiarIndActivo,
      GetAnular,
      ModalViewNotaEntradaRef,
      GetVer,
      GetFinalizar,
      FuncionesGenerales,
    }
  },
}
</script>
  
  <style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  